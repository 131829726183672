/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  --backgroundLocalTeam: #0d3aff;
  --colorLocalTeam: #fff;
  
  --backgroundVisitorTeam: #0d3aff;
  --colorVisitorTeam: #fff;
  
 
 
 


  /** primary **/
  --ion-color-primary: #0d3aff;
  --ion-color-primary-rgb: 240, 101, 47;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0929c9;
  --ion-color-primary-tint: #061da5;

  /** secondary **/
  --ion-color-secondary: #fff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #0d3aff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;


  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;





  --ion-background-color: #262626;
	--ion-background-color-rgb: 38,38,38;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255,255,255;

	--ion-color-step-50: #313131;
	--ion-color-step-100: #3c3c3c;
	--ion-color-step-150: #474747;
	--ion-color-step-200: #515151;
	--ion-color-step-250: #5c5c5c;
	--ion-color-step-300: #676767;
	--ion-color-step-350: #727272;
	--ion-color-step-400: #7d7d7d;
	--ion-color-step-450: #888888;
	--ion-color-step-500: #939393;
	--ion-color-step-550: #9d9d9d;
	--ion-color-step-600: #a8a8a8;
	--ion-color-step-650: #b3b3b3;
	--ion-color-step-700: #bebebe;
	--ion-color-step-750: #c9c9c9;
	--ion-color-step-800: #d4d4d4;
	--ion-color-step-850: #dedede;
	--ion-color-step-900: #e9e9e9;
	--ion-color-step-950: #f4f4f4;


  


}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #0769a5;
  --ion-color-tint: #1da1f4;
}
.ion-color-discord {
	--ion-color-base: #7289da;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255,255,255;
	--ion-color-shade: #0769a5;
	--ion-color-tint: #1da1f4;
}
.ion-color-instagram {
	--ion-color-base: #833AB4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255,255,255;
	--ion-color-shade: #0769a5;
	--ion-color-tint: #1da1f4;
}


.ion-color-nul {
	--ion-color-base: #313131;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-seconde {
	--ion-color-base: #3c3c3c;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}


.ion-color-jaune {
	--ion-color-base: #e1f249;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #fff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #d20a46;
	--ion-color-tint: #78c288;
}

.ion-color-vert {
	--ion-color-base: #35b827;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #fff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #d20a46;
	--ion-color-tint: #78c288;
}

.ion-color-rouge {
	--ion-color-base: #eb445a;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #fff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #d20a46;
	--ion-color-tint: #78c288;
}

.ion-color-league-1 {
	--ion-color-base: #990000;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #fff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #d20a46;
	--ion-color-tint: #78c288;
}

.ion-color-league-2 {
	--ion-color-base: #0d3aff;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-league-10 {
	--ion-color-base: #fff;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

  /** uro **/
.ion-color-league-960 {
	--ion-color-base: #143cdb;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-league-4 {
	--ion-color-base: #143cdb;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}


  /** ligue1 **/

.ion-color-league-61 {
	--ion-color-base: #fff;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-league-5 {
	--ion-color-base: #2d3750;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #fff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-league-242 {
	--ion-color-base: #eb445a;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #fff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}



.ion-color-0 {
	--ion-color-base: #ff560d;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-1 {
	--ion-color-base: #eb445a;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-2 {
	--ion-color-base: #ed576b;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-3 {
	--ion-color-base: #ff9e0d;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-4 {
	--ion-color-base: #ffba0d;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-5 {
	--ion-color-base: #fee8b1;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-6 {
	--ion-color-base: #dbfeb1;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-7 {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-8 {
	--ion-color-base: #1ace1a;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-9 {
	--ion-color-base: #19f401;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-10 {
	--ion-color-base: #247b07;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}
/* 
.ion-color-1 {
	--ion-color-base: #D71A0E;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-2 {
	--ion-color-base: #F09B1A;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-3 {
	--ion-color-base: #F0CE1D;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-4 {
	--ion-color-base: #F09B1A;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-4 {
	--ion-color-base: #B0C725;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-6 {
	--ion-color-base: #23C3A3;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-10 {
	--ion-color-base: #00A64A;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #000000;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}
 */

.ion-color-local-team {
	--ion-color-base: var(--backgroundLocalTeam);
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast:var(--colorLocalTeam);
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-visitor-team {
	--ion-color-base: var(--backgroundVisitorTeam);
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: var(--colorVisitorTeam);
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

/* .ion-color-dark {
	--ion-color-base: #ed576b;
	--ion-color-base-rgb: 105,187,123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 0,0,0;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
} */

ion-item.match-item {
  margin-top: 8px;
}

ion-badge.position {
	width: 32px;
	height: 32px;
	--padding-top: 10px;
	margin-inline-end: 16px;
	font-size: 10px;
}

ion-badge.position3 {
	width: 32px;
	height: 32px;
	--padding-top: 10px;
	margin-inline-end: 0;
}



ion-badge.square {
	width: 32px;
	height: 32px;
	--padding-top: 10px;
}

ion-badge.round {
	width: 40px;
	height: 40px;
	--padding-top: 14px;
	border-radius: 50%;
}

ion-button.square {
	width: 32px;
	height: 32px;
	margin-inline-start: 16px;
}

ion-button.square-end {
	width: 32px;
	height: 32px;
	margin-inline-end: 16px;
}

ion-toolbar.match-toolbar-left {
	width: 50%;
	

}

ion-toolbar.match-toolbar-right {
	position: absolute;
	width: 50%;
	right: 0;
	top: 0;

}

ion-item.local {
	--highlight-color-focused: var(--backgroundLocalTeam);
}

ion-item.visitor {
	--highlight-color-focused: var(--backgroundVisitorTeam);
}

.pitch ion-item {
	--highlight-color-focused: var(--backgroundVisitorTeam);
	--highlight-color-focused: transparent;
}


ion-progress-bar {
	margin-inline-start: 10px;
	background: #fff;
}

.action-sheet-button.sc-ion-action-sheet-ios, .action-sheet-title.sc-ion-action-sheet-ios {
	color: #fff;

    
}
ion-toolbar, .toolbar-background {
	transition: all 1s linear;
}

ion-item-divider {
	
  }


 




/*   .modal-handle {
display: none !important;
visibility: hidden;
  }
 */


/* 
.pitch {

    position: relative;
    background-repeat: round;
	background-image: url(/public/assets/imgs/pitch.svg);

    background-size: cover;
	background-color: #fff;
}

.pitch.responsive {
	padding-bottom: 58.3%;
	width: 100%;
}

.fill {
	height: 100%;
	width: 100%;
}

.pitch.small {
	height: 600px;
	width: 400px;
}

.pitch.normal {
	height: 525px;
	width: 900px;
}

.pitch.big {
	height: 700px;
	width: 1200px;
}











.teams {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
}

.team {

    height: 100%;
    width: 100%;



}





.goalkeeper {
	height: 20%;
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	
}

.lines {

	height: 80%;
	width: 100%;
	float: right;
	display: block;

	
}

.line {

	height: 25%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;

}




.player-view {

    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    
}

.player {

    display: flex;
    justify-content: center;

    position: absolute;
    border-radius: 50%;
    padding-top: 5%;
    width: 5%;

    font-size: 14px;

    
} */

/* &.clickable {
    cursor: pointer;
} */

/* .number {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.name {
    position: absolute;
    bottom: -50%;
    width: max-content;
} */



.pitch3 {
	position: relative;
	/* background-image: url(/public/assets/imgs/pitch.svg);
	background-size:  400px 600px; */

/* 	background-color: #111;
 */	height: 800px;
	width: 800px;

	margin: 0 auto 80px auto;
	display: none;
}

.pitch3-player {
	width: 100px;
	height: 100px;
	text-align: center;
}

.pitch3-img {
	position: absolute;
	display: block;
	transform-origin: bottom center;
	transform: scale(1.51);
	pointer-events: none;
	bottom: -20%;
	left: 5%;
	width: 90%;
	height: 76%;
	user-select: none;
  }

.pitch {
	position: relative;
	/* background-image: url(/public/assets/imgs/pitch.svg);
	background-size:  400px 600px; */

/* 	background-color: #111;
 */	height: 400px;
	width: 400px;

	margin: 0 auto 80px auto;
	display: none;
}



.pitch ion-select, .pitch3 ion-select {
	opacity: 0;
}

.pitch ion-label, .pitch3 ion-label {
	white-space: normal !important;
}

.pitch ion-item, .pitch3 ion-item {
	--background: none;
}

.pitch-player {
	width: 100px;
	height: 70px;
	text-align: center;
}

.Outlines {
  position: absolute;
  display: block;
  transform-origin: bottom center;
  transform: scale(1.41);
  pointer-events: none;
  bottom: -9%;
  left: 5%;
  width: 90%;
  height: 76%;
  user-select: none;
}

.f_3-4-3, .f_3-4-2-1, .f_3-5-2, .f_3-1-4-2, .f_4-2-3-1, 
.f_4-3-3, .f_4-4-2, .f_4-4-1-1, .f_4-3-1-2, .f_5-3-2, .f_4-1-4-1, 
.f_3-4-1-2, .f_4-5-1, .f_5-4-1, .f_3-5-1-1 {
	display: block;
}

.g_1-1, .g_1-2, .g_1-3, .g_1-4, .g_1-5,
.g_2-1, .g_2-2, .g_2-3, .g_2-4, .g_2-5,
.g_3-1, .g_3-2, .g_3-3, .g_3-4, .g_3-5,
.g_4-1, .g_4-2, .g_4-3, .g_4-4, .g_4-5,
.g_5-1, .g_5-2, .g_5-3, .g_5-4, .g_5-5
 {
	position: absolute;
	z-index: 9999;
	width: 100px;
	height: 70px;
	text-align: center;

	overflow: visible;

	--padding-start: 0;
    --inner-padding-end: 0;
}



/* img {
	height: 30px;
	width: 30px;
	border-radius: 50%;
} */




.f_3-5-1-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-1-1 .g_2-1 {
	bottom: 12%; 
	left: 10%;
}

.f_3-5-1-1 .g_2-2 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-1-1 .g_2-3 {
	bottom: 12%; 
	right: 10%;
}

.f_3-5-1-1 .g_3-1 {
	bottom: 35%; 
	left: 0;
}

.f_3-5-1-1 .g_3-2 {
	bottom: 50%;  
	left: 15%;
}

.f_3-5-1-1 .g_3-3 {
	bottom: 40%;  
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-1-1 .g_3-4 {
	bottom: 50%;  
	right: 15%;
}

.f_3-5-1-1 .g_3-5 {
	bottom: 35%; 
	right: 0;
}

.f_3-5-1-1 .g_4-1 {
	top: 23%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-1-1 .g_5-1 {
	top: 3%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}







.f_3-4-3 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-3 .g_2-1 {
	bottom: 12%; 
	left: 10%;
}

.f_3-4-3 .g_2-2 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-3 .g_2-3 {
	bottom: 12%; 
	right: 10%;
}

.f_3-4-3 .g_3-1 {
	bottom: 40%;  
	left: 0;
}

.f_3-4-3 .g_3-2 {
	bottom: 40%;  
	left: 25%;
}

.f_3-4-3 .g_3-3 {
	bottom: 40%;  
	right: 25%;
}

.f_3-4-3 .g_3-4 {
	bottom: 40%;  
	right: 0;
}

.f_3-4-3 .g_4-1 {
	top: 15%; 
	left: 10%;
}

.f_3-4-3 .g_4-2 {
	top: 10%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-3 .g_4-3 {
	top: 15%; 
	right: 10%;
}









.f_3-4-2-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-2-1 .g_2-1 {
	bottom: 12%; 
	left: 10%;
}

.f_3-4-2-1 .g_2-2 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-2-1 .g_2-3 {
	bottom: 12%; 
	right: 10%;
}

.f_3-4-2-1 .g_3-1 {
	bottom: 40%; 
	left: 0;
}

.f_3-4-2-1 .g_3-2 {
	bottom: 40%;  
	left: 25%;
}

.f_3-4-2-1 .g_3-3 {
	bottom: 40%;  
	right: 25%;
}

.f_3-4-2-1 .g_3-4 {
	bottom: 40%;  
	right: 0;
}

.f_3-4-2-1 .g_4-1 {
	top: 21%; 
	left: 10%;
}

.f_3-4-2-1 .g_4-2 {
	top: 21%; 
	right: 10%;
}

.f_3-4-2-1 .g_5-1 {
	top: 10%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}











.f_3-4-1-2 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-1-2 .g_2-1 {
	bottom: 12%; 
	left: 10%;
}

.f_3-4-1-2 .g_2-2 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-1-2 .g_2-3 {
	bottom: 12%; 
	right: 10%;
}

.f_3-4-1-2 .g_3-1 {
	bottom: 40%; 
	left: 0;
}

.f_3-4-1-2 .g_3-2 {
	bottom: 40%;  
	left: 25%;
}

.f_3-4-1-2 .g_3-3 {
	bottom: 40%;  
	right: 25%;
}

.f_3-4-1-2 .g_3-4 {
	bottom: 40%;  
	right: 0;
}

.f_3-4-1-2 .g_4-1 {
	top: 23%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-4-1-2 .g_5-1 {
	top: 10%; 
	left: 22%;
}

.f_3-4-1-2 .g_5-2 {
	top: 10%; 
	right: 22%;
}











.f_3-5-2 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-2 .g_2-1 {
	bottom: 12%; 
	left: 10%;
}

.f_3-5-2 .g_2-2 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-2 .g_2-3 {
	bottom: 12%; 
	right: 10%;
}

.f_3-5-2 .g_3-1 {
	bottom: 35%; 
	left: 0;
}

.f_3-5-2 .g_3-2 {
	bottom: 50%;  
	left: 15%;
}

.f_3-5-2 .g_3-3 {
	bottom: 40%;  
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-5-2 .g_3-4 {
	bottom: 50%;  
	right: 15%;
}

.f_3-5-2 .g_3-5 {
	bottom: 35%; 
	right: 0;
}

.f_3-5-2 .g_4-1 {
	top: 11%; 
	left: 25%;
}

.f_3-5-2 .g_4-2 {
	top: 11%; 
	right: 25%;
}









.f_3-1-4-2 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-1-4-2 .g_2-1 {
	bottom: 12%; 
	left: 10%;
}

.f_3-1-4-2 .g_2-2 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_3-1-4-2 .g_2-3 {
	bottom: 12%; 
	right: 10%;
}

.f_3-1-4-2 .g_3-1 {
	bottom: 32%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_3-1-4-2 .g_4-1 {
	bottom: 48%; 
	left: 0;
}

.f_3-1-4-2 .g_4-2 {
	bottom: 48%;  
	left: 25%;
}

.f_3-1-4-2 .g_4-3 {
	bottom: 48%;  
	right: 25%;
}

.f_3-1-4-2 .g_4-4 {
	bottom: 48%;  
	right: 0;
}

.f_3-1-4-2 .g_5-1 {
	top: 11%; 
	left: 25%;
}

.f_3-1-4-2 .g_5-2 {
	top: 11%; 
	right: 25%;
}









.f_4-2-3-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_4-2-3-1 .g_2-1 {
	bottom: 12%; 
	left: 0;
}

.f_4-2-3-1 .g_2-2 {
	bottom: 12%;  
	left: 25%;
}

.f_4-2-3-1 .g_2-3 {
	bottom: 12%;  
	right: 25%;
}

.f_4-2-3-1 .g_2-4 {
	bottom: 12%;  
	right: 0;
}

.f_4-2-3-1 .g_3-1 {
	bottom: 33%; 
	left: 25%;
}

.f_4-2-3-1 .g_3-2 {
	bottom: 33%; 
	right: 25%;
}

.f_4-2-3-1 .g_4-1 {
	top: 29%; 
	left: 10%;
}

.f_4-2-3-1 .g_4-2 {
	top: 29%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-2-3-1 .g_4-3 {
	top: 29%; 
	right: 10%;
}

.f_4-2-3-1 .g_5-1 {
	top: 10%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}









.f_4-1-4-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_4-1-4-1 .g_2-1 {
	bottom: 11%;  
	left: 0;
}

.f_4-1-4-1 .g_2-2 {
	bottom: 11%;  
	left: 25%;
}

.f_4-1-4-1 .g_2-3 {
	bottom: 11%;  
	right: 25%;
}

.f_4-1-4-1 .g_2-4 {
	bottom: 11%;  
	right: 0;
}

.f_4-1-4-1 .g_3-1 {
	bottom: 32%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-1-4-1 .g_4-1 {
	bottom: 50%;  
	left: 0;
}

.f_4-1-4-1 .g_4-2 {
	bottom: 50%;  
	left: 25%;
}

.f_4-1-4-1 .g_4-3 {
	bottom: 50%;  
	right: 25%;
}

.f_4-1-4-1 .g_4-4 {
	bottom: 50%;  
	right: 0;
}

.f_4-1-4-1 .g_5-1 {
	top: 11%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}










.f_4-3-3 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_4-3-3 .g_2-1 {
	bottom: 12%; 
	left: 0;
}

.f_4-3-3 .g_2-2 {
	bottom: 12%;  
	left: 25%;
}

.f_4-3-3 .g_2-3 {
	bottom: 12%;  
	right: 25%;
}

.f_4-3-3 .g_2-4 {
	bottom: 12%;  
	right: 0;
}

.f_4-3-3 .g_3-1 {
	bottom: 40%; 
	left: 10%;
}

.f_4-3-3 .g_3-2 {
	bottom: 40%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-3-3 .g_3-3 {
	bottom: 40%; 
	right: 10%;
}

.f_4-3-3 .g_4-1 {
	top: 15%; 
	left: 10%;
}

.f_4-3-3 .g_4-2 {
	top: 10%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-3-3 .g_4-3 {
	top: 15%; 
	right: 10%;
}












.f_4-4-1-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_4-4-1-1 .g_2-1 {
	bottom: 11%;  
	left: 0;
}

.f_4-4-1-1 .g_2-2 {
	bottom: 11%;  
	left: 25%;
}

.f_4-4-1-1 .g_2-3 {
	bottom: 11%;  
	right: 25%;
}

.f_4-4-1-1 .g_2-4 {
	bottom: 11%;  
	right: 0;
}

.f_4-4-1-1 .g_3-1 {
	bottom: 40%;  
	left: 0;
}

.f_4-4-1-1 .g_3-2 {
	bottom: 40%;  
	left: 25%;
}

.f_4-4-1-1 .g_3-3 {
	bottom: 40%;  
	right: 25%;
}

.f_4-4-1-1 .g_3-4 {
	bottom: 40%;  
	right: 0;
}

.f_4-4-1-1 .g_4-1 {
	top: 23%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-4-1-1  .g_5-1 {
	top: 3%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}















.f_4-4-2 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_4-4-2 .g_2-1 {
	bottom: 11%;  
	left: 0;
}

.f_4-4-2 .g_2-2 {
	bottom: 11%;  
	left: 25%;
}

.f_4-4-2 .g_2-3 {
	bottom: 11%;  
	right: 25%;
}

.f_4-4-2 .g_2-4 {
	bottom: 11%;  
	right: 0;
}

.f_4-4-2 .g_3-1 {
	bottom: 40%;  
	left: 0;
}

.f_4-4-2 .g_3-2 {
	bottom: 40%;  
	left: 25%;
}

.f_4-4-2 .g_3-3 {
	bottom: 40%;  
	right: 25%;
}

.f_4-4-2 .g_3-4 {
	bottom: 40%;  
	right: 0;
}

.f_4-4-2 .g_4-1 {
	top: 11%; 
	left: 25%;
}

.f_4-4-2 .g_4-2 {
	top: 11%; 
	right: 25%;
}












.f_4-3-1-2 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}


.f_4-3-1-2 .g_2-1 {
	bottom: 11%;  
	left: 0;
}

.f_4-3-1-2 .g_2-2 {
	bottom: 11%;  
	left: 25%;
}

.f_4-3-1-2 .g_2-3 {
	bottom: 11%;  
	right: 25%;
}

.f_4-3-1-2 .g_2-4 {
	bottom: 11%;  
	right: 0;
}

.f_4-3-1-2 .g_3-1 {
	bottom: 42%;  
	left: 10%;
}

.f_4-3-1-2 .g_3-2 {
	bottom: 32%;  
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-3-1-2 .g_3-3 {
	bottom: 42%;  
	right: 10%;
}

.f_4-3-1-2 .g_4-1 {
	top: 29%;  
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-3-1-2 .g_5-1 {
	top: 11%; 
	left: 25%;
}

.f_4-3-1-2 .g_5-2 {
	top: 11%; 
	right: 25%;
}
























.f_4-5-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-5-1 .g_2-1 {
	bottom: 11%;  
	left: 0;
}

.f_4-5-1 .g_2-2 {
	bottom: 11%;  
	left: 25%;
}

.f_4-5-1 .g_2-3 {
	bottom: 11%;  
	right: 25%;
}

.f_4-5-1 .g_2-4 {
	bottom: 11%;  
	right: 0;
}

.f_4-5-1 .g_3-1 {
	bottom: 35%; 
	left: 0;
}

.f_4-5-1 .g_3-2 {
	bottom: 50%;  
	left: 15%;
}

.f_4-5-1 .g_3-3 {
	bottom: 40%;  
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_4-5-1 .g_3-4 {
	bottom: 50%;  
	right: 15%;
}

.f_4-5-1 .g_3-5 {
	bottom: 35%; 
	right: 0;
}

.f_4-5-1 .g_4-1 {
	top: 11%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}















.f_5-3-2 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_5-3-2 .g_2-1 {
	bottom: 18%; 
	left: -4%;
}

.f_5-3-2 .g_2-2 {
	bottom: 12%; 
	left: 15%;
}

.f_5-3-2 .g_2-3 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_5-3-2 .g_2-4 {
	bottom: 12%; 
	right: 15%;
}

.f_5-3-2 .g_2-5 {
	bottom: 18%; 
	right: -4%;
}


.f_5-3-2 .g_3-1 {
	bottom: 40%; 
	left: 10%;
}

.f_5-3-2 .g_3-2 {
	bottom: 40%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_5-3-2 .g_3-3 {
	bottom: 40%; 
	right: 10%;
}


.f_5-3-2 .g_4-1 {
	top: 11%; 
	left: 25%;
}

.f_5-3-2 .g_4-2 {
	top: 11%; 
	right: 25%;
}













.f_5-4-1 .g_1-1 {
	bottom: -35px; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_5-4-1 .g_2-1 {
	bottom: 18%; 
	left: -4%;
}

.f_5-4-1 .g_2-2 {
	bottom: 12%; 
	left: 15%;
}

.f_5-4-1 .g_2-3 {
	bottom: 12%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}

.f_5-4-1 .g_2-4 {
	bottom: 12%; 
	right: 15%;
}

.f_5-4-1 .g_2-5 {
	bottom: 18%; 
	right: -4%;
}






.f_5-4-1 .g_3-1 {
	bottom: 40%; 
	left: 0;
}

.f_5-4-1 .g_3-2 {
	bottom: 40%;  
	left: 25%;
}

.f_5-4-1 .g_3-3 {
	bottom: 40%;  
	right: 25%;
}

.f_5-4-1 .g_3-4 {
	bottom: 40%;  
	right: 0;
}

.f_5-4-1 .g_4-1 {
	top: 10%; 
	right: calc(50% - 50px);
	left: calc(50% - 50px);
}








































.roadmap-grid {
	position:relative;
	width: 875px;
	height: auto;
	margin: 0 auto;
  }
  
  
  .roadmap-line {
	position:absolute;
	left: 50%;
	top:0;
	background:#c1cef1;
	height:100%;
	width:2px;
	content:'';
	z-index:9
  }
  
  .roadmap-line:after,.roadmap-line:before{position:absolute;background:#c1cef1;height:10px;width:10px;border-radius:50%;content:'';left:-4px}
  .roadmap-line:before{top:0}
  .roadmap-line:after{bottom:0}
  
  
  .roadmap {
	display: flex;
	position: relative;
	padding-bottom: 30px;
  
  }
  
  .roadmap-step {
	border-radius:5px;
	position:relative;
	padding:26px 15px 0;
	position:relative;
	max-width:380px;
	z-index: 11;
	text-align: left;
  
	width: 100%;
  }
  
  .roadmap-step p {
	line-height: 20px;
  }
  
  .roadmap-step:before,.roadmap-step:after{position:absolute;content:''}
  .roadmap-step:before{height:18px;width:18px;border-radius:50%;border:5px solid #FF0600;background:#fff;left:-41px;top:34px;z-index:1}
  .roadmap-step:after{height:2px;width:22px;background:#dbe4f7;left:-22px;top:42px}
  .roadmap-step p:last-child{margin-bottom:0}
  
  .roadmap-head {
	margin-bottom:10px;
	position:relative;
  }
  
  .roadmap-left  .roadmap-head {
  
	
	text-align: right;
  }
  
  
  
  .roadmap-title  {
	font-weight:700;
	font-size:20px;
  text-transform: uppercase;
  }
  
  .chakra-ui-light .roadmap-title {
	color: #fff;
  }
  
  .roadmap-left .roadmap-title {
  text-align: right;
  width: 100%;
  }
  
  
  
  .roadmap-right {
	justify-content: flex-end;
  }
  
  
  .roadmap-left{
	justify-content:flex-start;
	margin-top:0;
	margin-left:-33px;
  
  }
  .roadmap-left .roadmap-step{margin-left:33px;margin-right:80px}
  .roadmap-left .roadmap-step:before{left:auto;right:-44px}
  .roadmap-left .roadmap-step:after{left:auto;right:-27px}
  
  
  
  